import React, { useEffect, useState } from 'react';
import './LabelLogistic.scss';
import { ListOrderDetail } from '../../../api';
import Barcode from 'react-barcode';
import PrintIcon from '../../../assets/img/print-icon.png';


const LabelLogistic = (props) => {
    const [data, setData] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        const content = { transactionId: props.match.params.id };

        ListOrderDetail(content).then(res => {
            if (res) setData(res);
        }).catch(err => console.log(err));
    };

    const formatuang = (amount) => {
        if (amount === null) amount = 0;
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const barcodeOptions = { width: 1, height: 40, font: "sans-serif", fontSize: 18 };
    const courierLogos = {
        jne: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/32/JNE_Logo.svg/1200px-JNE_Logo.svg.png",
        wahana: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/30/Wahana-Logo.svg/1200px-Wahana-Logo.svg.png",
        tiki: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3a/Tiki_ID.svg/1200px-Tiki_ID.svg.png",
        pos: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/Pos_Indonesia.svg/1200px-Pos_Indonesia.svg.png"
    };

    return (
        <div>
            <div className="no-print button-print" onClick={() => window.print()}>
                <img src={PrintIcon} width="50" alt="" /> Cetak
            </div>

            {data !== null && (
                <div className="label-send-wrapper">
                    {/* 1. Logo IPB Press & Label Pengiriman */}
                    <div className="header-brand">
                        <img src= "https://res.cloudinary.com/dhitaaaa/image/upload/v1741812951/logo-ipb-press_1_ewnxr0.jpg" height="50" alt="IPB Press" />
                        {courierLogos[data.result.courier.toLowerCase()] ? (
                            <img 
                                src={courierLogos[data.result.courier.toLowerCase()]} 
                                height="50" 
                                alt={data.result.courier} 
                            />
                        ) : (
                            <h3>{data.result.courier}</h3>
                        )}
                    </div>

                    <div className="line-sparator"></div>

                    {/* 2. Barcode (Tengah) */}
                    <div className="barcode-shipment" style={{ textAlign: 'center' }}>
                        <Barcode value={data.result.transactionId} {...barcodeOptions} />
                        <small style={{ display: 'block', marginTop: '5px' }}>
                            <i>*Kode Transaksi ini Bukan Resi Pengiriman</i>
                        </small>
                    </div>

                    <div className="line-sparator"></div>

                    <div className="address-wrapper">
                        <table width="100%" style={{ borderCollapse: "collapse" }}>
                            <tbody>
                                <tr>
                                    <td width="50%" style={{ verticalAlign: "top", padding: "8px", textAlign: "left", fontSize: "14px" }}>
                                    <p><strong>Kepada: </strong> <strong>{data.result.Address_Customer.shipTo}</strong></p>
                                        <p>{data.result.Address_Customer.address1}</p>
                                        <p>{data.result.Address_Customer.city}, {data.result.Address_Customer.subdistrict}, {data.result.Address_Customer.province}</p>
                                        <p>{data.result.Address_Customer.phone}</p>
                                    </td>
                                    <td width="50%" style={{ verticalAlign: "top", padding: "8px", textAlign: "left", fontSize: "14px" }}>
                                        <p><strong>Dari: </strong><strong>{data.result.Company.companyName}</strong></p>
                                        <p>{data.result.Company.address}</p>
                                        <p>{data.result.Company.phone}</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="line-sparator"></div>

                    {/* 4. Berat & Jasa Pengiriman */}
                    <div className="shipment-info">
                        <div className="cell">Berat: <strong>{data.result.weight / 1000} kg</strong></div>
                        <div className="cell">Jasa Pengiriman: <strong>{data.result.courier} ({data.result.etd})</strong></div>
                        <div className="cell">Ongkir: <strong>Rp {formatuang(data.result.value)}</strong></div>
                    </div>

                    <div className="line-sparator"></div>

                    {/* 5. Produk */}
                    <div className="product-section">
                    <div className="product-section">
                        <table width="100%">
                            <thead>
                                <tr>
                                    <th>Nama Produk</th>
                                    <th>Qty</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.result.OrderProducts.map((product, index) => (
                                    <tr key={index}>
                                        <td>{product.Product.name}</td>
                                        <td>{product.qty}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LabelLogistic;